<template>
  <div>
    <div class="page-content">
      <h1>A propos de Drogues et Société</h1>
      <p>L’association Drogues et Société est née en 1985 d’une initiative de la ville de Créteil, à laquelle se sont associées au fil des années les villes de Maisons-Alfort, Bonneuil-sur-Marne, puis Alfortville et Limeil-Brévannes, dans le cadre de la Communauté d’agglomération de la Plaine centrale du Val-de-Marne, faisant partie, depuis début 2016, du Grand Paris Sud Est Avenir.</p>
      <p>Les collaborations engagées avec ces communes ont déterminé une inscription forte de nos services au plan local et la nécessité de considérer l’importante complexité du phénomène drogues, le contexte dans lequel il s’inscrit et son impact. Et ce, tant pour les usagers, leur entourage, les habitants que pour les professionnels et autres acteurs sociaux.</p>
      <p>Ainsi, les dimensions de précarité, d’exclusion sociale auxquelles ce phénomène peut être associé, la peur et le sentiment d’isolement qu’il peut générer pour l‘entourage, le sentiment d’impuissance auquel sont confrontés les intervenants de proximité ont impliqué pour notre équipe de construire différentes réponses, au-delà de celles plus spécifiquement destinées à la prise en charge du sujet.</p>
      <p>Les stratégies mises en œuvre ont été déterminées par la nécessaire adaptation à la réalité d’un terrain, où la complexité des problèmes liés aux drogues devait être affrontée. Il s’agissait de parvenir à la construction de réponses concertées pour une meilleure socialisation de ces problèmes, afin de réduire l’exclusion dont étaient victimes les sujets dépendants ou usagers de drogues et de pouvoir ainsi mieux les accompagner.</p>
      <p>Depuis 1995, Drogues et Société développe un projet pour la formation et l'accès à l'emploi de publics toxicodépendant.</p>
    </div>
    <div class="title-block yellow">
      <div class="page-content">
        <h2>Découvrez l'équipe</h2>
      </div>
    </div>
    <div class="page-content">
      <div class="staff-wrapper">
        <div class="item">
          <div style="white-space: pre-wrap" v-html="management"></div>
        </div>
        <div class="item">
          <div style="white-space: pre-wrap" v-html="staff"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      management: require('@/assets/cms/bureau-b8d9314c-1555-4f7e-80f0-31c06df0b2f3.html'),
      staff: require('@/assets/cms/equipe-1590eff6-61a5-43db-b9cd-471d0eec4369.html'),
    }
  }

}
</script>

<style lang="scss" scoped>

.staff-wrapper {
  display: flex;

  .item {
    flex: 1;

    h3 {
      margin: 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}

@media only screen and (max-width: 700px) {

  .staff-wrapper {
    flex-direction: column;
  }
}

</style>
